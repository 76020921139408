import React from "react";
import { ThemeProvider } from "styled-components";
import media from "./Media";

const theme = {
    color: {
        primary: "#00AEEF",
        secondary: "#F07C00",
        text: "#3C3C3C",
        grey100: "#F0F0F0",
        grey200: "#8E8E8E",
        grey300: "#3C3C3C",
        white: "#FFFFFF",
    },
    font: {
        serif: "Lora, serif",
        sans: "Open Sans, sans-serif",
    },
    grid: {
        gutter: 30,
    },
    media: {
        ...media,
        maxWidth: 1280,
    },
};

const Theme = ({ children }) => <ThemeProvider theme={theme}>{children}</ThemeProvider>;

export default Theme;
