import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Color from "color";
import Select from "react-select";

const Form = styled.form`
    background-color: ${(props) => props.theme.color.white};
    border-radius: 25px;
    display: flex;
    flex-direction: column;

    ${({ theme }) => theme.media.large`
        justify-content: space-between;
        flex-direction: row;
        height: 50px;
        `};

    & input.keyword {
        background-color: transparent;
        font-family: ${(props) => props.theme.font.sans};
        border: none;
        height: 50px;
        outline: none;
        margin: 3px 25px 0;
        padding: 0;
        font-size: 16px;
        border-bottom: 1px solid hsl(0, 0%, 80%);

        ${({ theme }) => theme.media.large`
            border: none;
            border-right: 1px solid hsl(0, 0%, 80%);
            width: 38%;
            height: 34px;
            margin-top: 8px;
            `};
    }

    & button {
        background-color: ${(props) => props.theme.color.secondary};
        font-family: ${(props) => props.theme.font.sans};
        font-weight: 700;
        border: none;
        padding: 0 25px;
        cursor: pointer;
        outline: none;
        border-radius: 23px;
        height: 46px;
        float: right;
        font-size: 16px;
        margin: 2px;
        color: ${(props) => props.theme.color.white};
        transition: background-color 0.2s ease-out;
        ${({ theme }) => theme.media.large`
            flex-base: 46px;
        `};
        flex-shrink: 0;

        &:hover {
            background-color: ${(props) => Color(props.theme.color.secondary).lighten(0.1)};
        }

        &:active {
            background-color: ${(props) => Color(props.theme.color.secondary).darken(0.1)};
        }
    }
`;

const SelectContainer = styled.div`
    padding: 0 15px;
    ${({ theme }) => theme.media.large`
        width: 38%;
        padding: 0;
    `};
`;

const customStyles = {
    control: (provided, state) => {
        return {
            ...provided,
            borderStyle: "none !important",
            boxShadow: "none !important",
            height: "50px",
            "&:hover": {
                borderStyle: "none",
            },
            "&:focus": {
                outline: "none",
                borderStyle: "none",
            },
        };
    },
    option: (base, state) => ({
        ...base,
        backgroundColor: state.isSelected ? "#F07C00" : "white",
        fontWeight: state.isSelected ? "700" : "400",
        ":hover": {
            backgroundColor: state.isSelected ? "#F07C00" : "#f9e4cc",
        },
        ":active": {
            backgroundColor: state.isSelected ? "#F07C00" : "#f9e4cc",
        },
    }),
    indicatorSeparator: (base, state) => {
        console.log(base);
        return {
            ...base,
            backgroundColor: "transparent",
        };
    },
};

const SearchForm = ({ specialities }) => {
    const [wordshred, setWordshred] = useState("");
    const [speciality, setSpeciality] = useState();
    const history = useHistory();

    const handleSearch = (ev) => {
        ev.preventDefault();
        const wordshredPart = wordshred !== "" ? `/${wordshred}` : "/&";
        const specialityPart = speciality ? `/${speciality.value}` : "";
        const url = `/kereses${wordshredPart}${specialityPart}`;
        history.push(url);
    };

    return (
        <Form onSubmit={(ev) => handleSearch(ev)}>
            <input
                className="keyword"
                type="text"
                placeholder="kulcsszó"
                value={wordshred}
                onChange={({ target: { value } }) => setWordshred(value)}
                autoFocus
            />
            <SelectContainer>
                <Select
                    isClearable
                    placeholder="szakterület"
                    value={speciality}
                    onChange={(option) => setSpeciality(option)}
                    options={specialities}
                    styles={customStyles}
                />
            </SelectContainer>
            <button type="submit">Keresés</button>
        </Form>
    );
};

export default SearchForm;
