import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Button from "../common/Button";
import { sendApplication } from "../../api/common";

const Card = styled.div`
    border-radius: 3px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    padding: 20px;
    border-bottom: 3px solid ${({ theme }) => theme.color.primary};
`;

const Heading = styled.h3`
    font-family: ${(props) => props.theme.font.serif};
    font-size: 24px;
    margin: 0;
`;

const FormControl = styled.div`
    margin-bottom: 20px;
`;

const Label = styled.label`
    display: block;
`;

const Input = styled.input`
    width: 100%;
    padding: 10px;
    font-size: 18px;
`;

const Error = styled.p`
    font-size: 12px;
    font-style: italic;
    margin: 4px 0 0 0;
    color: ${({ theme }) => theme.color.grey200};
`;

const ApplicationForm = ({ jobId }) => {
    const [fields, setFields] = useState({
        name: { value: "", dirty: false, validation: "kötelező" },
        email: { value: "", dirty: false, validation: "kötelező" },
        phone: { value: "", dirty: false, validation: "kötelező" },
        acceptance: { value: false, dirty: false, validation: "kötelező" },
    });
    const [success, setSuccess] = useState(false);

    const isFormInvalid = () => {
        const validRes = Object.keys(fields).map((field) => (fields[field].validation ? true : false));
        console.log(validRes);
        return validRes.includes(true);
    };

    const handleFieldChange = (field, value) => {
        setFields({
            ...fields,
            [field]: {
                value,
                dirty: true,
                validation: validateField(field, value),
            },
        });
    };

    const validEmail = (email) => {
        // eslint-disable-next-line no-useless-escape
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    };

    const validateField = (field, value) => {
        let validationResults = value === "" ? "kötelező" : null;
        if (field === "email") {
            validationResults =
                !validEmail(value) && validationResults === null
                    ? "kérjük valós emailcímet adjon meg"
                    : validationResults;
        }
        if (field === "acceptance") {
            validationResults = !value;
        }
        return validationResults;
    };

    const handleFormSubmission = (ev) => {
        ev.preventDefault();
        const data = {
            email: fields.email.value,
            clientname: fields.name.value,
            telefon: fields.phone.value,
            emplhuntingid: jobId,
            outputtype: "http",
        };

        sendApplication(data).then((result) => setSuccess(true));
    };

    return (
        <Card>
            {!success && (
                <>
                    <Heading>Jelentkezés</Heading>
                    <p>
                        Lépjen kapcsolatba az Incorpora-val, és hagyjon üzenetet! Az Incorpora karrier tanácsadóink
                        hamarosan válaszolni fognak!
                    </p>
                    <form onSubmit={(ev) => handleFormSubmission(ev)}>
                        <FormControl>
                            <Label htmlFor="name">Név</Label>
                            <Input
                                id="name"
                                name="name"
                                value={fields.name.value}
                                onChange={({ target: { value } }) => handleFieldChange("name", value)}
                            />
                            {fields.name.validation && fields.name.dirty && <Error>{fields.name.validation}</Error>}
                        </FormControl>
                        <FormControl>
                            <Label htmlFor="email">Email</Label>
                            <Input
                                id="email"
                                name="email"
                                value={fields.email.value}
                                onChange={({ target: { value } }) => handleFieldChange("email", value)}
                            />
                            {fields.email.validation && fields.email.dirty && <Error>{fields.email.validation}</Error>}
                        </FormControl>
                        <FormControl>
                            <Label htmlFor="phone">Telefon</Label>
                            <Input
                                id="phone"
                                name="phone"
                                value={fields.phone.value}
                                onChange={({ target: { value } }) => handleFieldChange("phone", value)}
                            />
                            {fields.phone.validation && fields.phone.dirty && <Error>{fields.phone.validation}</Error>}
                        </FormControl>
                        <FormControl>
                            <Label htmlFor="acceptance">
                                <input
                                    id="acceptance"
                                    name="acceptance"
                                    type="checkbox"
                                    checked={fields.acceptance.value}
                                    onChange={({ target: { checked } }) => handleFieldChange("acceptance", checked)}
                                />{" "}
                                Az{" "}
                                <a
                                    href="https://www.incorpora-halozat.com/adatkezelesi"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                >
                                    Adatkezelési Tájékoztatót
                                </a>{" "}
                                elfogadom
                            </Label>
                        </FormControl>
                        <Button color="secondary" disabled={isFormInvalid()}>
                            Jelentkezés
                        </Button>
                    </form>
                </>
            )}
            {success && (
                <>
                    <Heading>Köszönjük</Heading>
                    <p>Köszönjük, hogy megtisztelt bizalmával és érdeklődik munkaerő-piaci szolgáltatásaink iránt!</p>
                    <p>
                        Az Ön által megadott adatok feldolgozását megkezdtük, tanácsadóink hamarosan felveszik Önnel a
                        kapcsolatot!
                    </p>
                </>
            )}
        </Card>
    );
};

export default ApplicationForm;
