import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
  body {
    background: ${(props) => props.theme.color.white};
    box-sizing: border-box;
    font-family: ${(props) => props.theme.font.sans};
    color: ${(props) => props.theme.color.text};
    & * {
      box-sizing: inherit;
    }
  }

  a {
    color: ${(props) => props.theme.color.primary};
  }
`;

export default GlobalStyles;
