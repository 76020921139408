import React from "react";
import HelmetWrapper from "../common/HelmetWrapper";
import meta from "./Home.meta.json";

import Hero from "./Hero";
import AboutUs from "./AboutUs";
import FeaturedJobs from "./FeaturedJobs";
import Stories from "./Stories";

const Home = ({ specialities, history }) => {
    return (
        <>
            <HelmetWrapper meta={meta} />
            <Hero specialities={specialities} history={history} />
            <AboutUs />
            <FeaturedJobs />
            <Stories />
        </>
    );
};

export default Home;
