import React from "react";
import styled from "styled-components";

import Card from "../common/Card";

const StoryWrapper = styled.div`
    margin: 0 0 30px;
    ${({ theme }) => theme.media.large`
        width: 47%;
        margin: 0 15px 30px;
        flex-grow: 1;
        flex-shrink: 0;
        flex-base: 47%;
    `};
`;

const CardHeader = styled.div`
    & img {
        float: left;
        width: 70px;
        height: 70px;
        margin-right: 20px;
        margin-bottom: 20px;
    }

    & h4 {
        font-weight: 700;
        margin: 0;
        padding-top: 13px;
    }

    & p {
        color: ${(props) => props.theme.color.primary};
        margin: 0;
    }
`;

const CardContent = styled.div`
    clear: both;
    font-family: ${(props) => props.theme.font.serif};
    font-style: italic;
    font-weight: normal;
    font-size: 18px;
    line-height: 26px;
`;

const StoryCard = ({ image, name, title, description }) => {
    return (
        <StoryWrapper>
            <Card>
                <CardHeader>
                    <img src={image} alt="" />
                    <h4>{name}</h4>
                    <p>{title}</p>
                </CardHeader>
                <CardContent>{description}</CardContent>
            </Card>
        </StoryWrapper>
    );
};

export default StoryCard;
