import React from "react";
import styled from "styled-components";
import SearchForm from "../SearchForm";

import heroBackground from "../../../images/incorpora-background.jpg";

const HeroWrapper = styled.div`
    background-image: url(${heroBackground});
    background-size: cover;
`;

const HeroOverlay = styled.div`
    background-color: rgba(0, 174, 239, 0.95);
    padding: 0 ${(props) => props.theme.grid.gutter / 2}px;
`;

const Col = styled.div`
    ${({ theme }) => theme.media.large`
    width: ${(props) => props.theme.media.maxWidth / 2}px;
    `};
`;

const HeroContent = styled.div`
    padding: 40px 0;
    max-width: ${(props) => props.theme.media.maxWidth}px;
    margin: 0 auto;

    ${({ theme }) => theme.media.large`
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    `};

    & h2 {
        margin: 0 0 10px 0;
        font-family: ${(props) => props.theme.font.serif};
        font-size: 32px;
        color: ${(props) => props.theme.color.white};
    }

    & p {
        margin: 0;
        font-family: ${(props) => props.theme.font.sans};
        font-size: 16px;
        line-height: 24px;
        color: ${(props) => props.theme.color.white};

        & > span {
            font-weight: 700;
        }

        &:first-child {
            margin-bottom: 10px;
        }
    }
`;

const Divider = styled.hr`
    margin: 20px 0;
    height: 1px;
    border: none;
    background-color: rgba(255, 255, 255, 0.3);
    ${({ theme }) => theme.media.large`
        display: none;
    `};
`;

const HeroCompact = ({ title, description, specialities, history }) => {
    return (
        <HeroWrapper>
            <HeroOverlay>
                <HeroContent>
                    <Col>
                        <h2>{title}</h2>
                        <p>{description}</p>
                        <Divider />
                    </Col>
                    <Col>
                        <p>Új keresés indítása</p>
                        <SearchForm specialities={specialities} />
                    </Col>
                </HeroContent>
            </HeroOverlay>
        </HeroWrapper>
    );
};

export default HeroCompact;
