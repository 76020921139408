import React from "react";
import Color from "color";
import styled from "styled-components";

const Btn = styled.button`
    background-color: ${({ kind, theme, color }) => (kind === "filled" ? theme.color[color] : theme.color.white)};
    font-family: ${(props) => props.theme.font.sans};
    font-weight: 700;
    border-color: ${({ kind, theme, color }) => (kind === "filled" ? "transparent" : theme.color[color])};
    border-width: ${({ kind }) => (kind === "filled" ? 0 : "2px")};
    border-style: ${({ kind }) => (kind === "filled" ? "none" : "solid")};
    padding: 0 25px;
    cursor: pointer;
    outline: none;
    border-radius: 23px;
    height: 46px;
    font-size: 16px;
    color: ${({ kind, theme, color }) => (kind === "filled" ? theme.color.white : theme.color[color])};
    transition: all 0.2s ease-out;

    &:hover {
        background-color: ${({ kind, theme, color }) =>
            kind === "filled" ? Color(theme.color[color]).lighten(0.1) : theme.color[color]};
        border-color: ${({ kind, theme, color }) =>
            kind === "filled" ? Color(theme.color[color]).lighten(0.1) : theme.color[color]};
        color: ${({ theme }) => theme.color.white};
    }

    &:active {
        background-color: ${({ theme, color }) => Color(theme.color[color]).darken(0.1)};
        border-color: ${({ theme, color }) => Color(theme.color[color]).darken(0.1)};
    }

    &:disabled {
        background-color: ${({ theme, color }) => Color(theme.color[color]).lighten(0.4)};
        border-color: ${({ theme, color }) => Color(theme.color[color]).lighten(0.4)};
        cursor: not-allowed;
    }
`;

const Button = ({ children, kind = "filled", color = "primary", onClick, disabled = false }) => (
    <Btn kind={kind} color={color} onClick={onClick} disabled={disabled}>
        {children}
    </Btn>
);

export default Button;
