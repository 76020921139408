import React from "react";
import styled from "styled-components";

const Heading = styled.h3`
    font-family: ${(props) => props.theme.font.serif};
    font-size: 24px;
    margin: 20px 0 0 0;
    &:first-letter {
        text-transform: uppercase;
    }
`;

const Speciality = styled.p`
    margin: 0;
    color: ${(props) => props.theme.color.primary};
    text-transform: uppercase;
    font-weight: bold;
    font-size: 12px;
`;

const Schedule = styled.p`
    margin: 0;
    color: ${(props) => props.theme.color.grey200};
`;

const Address = styled.p`
    margin: 0 0 20px 0;
    color: ${(props) => props.theme.color.grey200};
`;

const Description = styled.p`
    margin: 0;
    line-height: 20px;
`;

const JobDetails = ({ job }) => {
    return (
        <>
            {job.dictSpeciality && <Speciality>{job.dictSpeciality}</Speciality>}
            {job.dictWorkSchedule && <Schedule>{job.dictWorkSchedule}</Schedule>}
            {job.esJobName && <Heading>{job.posdescr}</Heading>}
            {job.esJobAddress && <Address>{job.esJobAddress}</Address>}
            {job.htmlJobDetails && <Description dangerouslySetInnerHTML={{ __html: job.htmlJobDetails }}></Description>}
        </>
    );
};

export default JobDetails;
