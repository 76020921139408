import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import logo from "../../../images/incorpora-logo.png";
import Hamburger from "../Hamburger";

const HeaderContainer = styled.header`
    background-color: ${(props) => props.theme.color.white};
    padding: 0 ${(props) => props.theme.grid.gutter / 2}px;
`;

const HeaderContent = styled.div`
    max-width: ${(props) => props.theme.media.maxWidth}px;
    margin: 0 auto;
    height: 60px;

    ${({ theme }) => theme.media.large`
        height: 80px;
        display: flex;
        align-items: center;
    `};
`;

const Img = styled.img`
    width: 100px;
    margin: 14px 0 0;
    ${({ theme }) => theme.media.large`
        width: 155px;
        margin: 0;
    `};
`;

const Nav = styled.nav`
    font-family: ${(props) => props.theme.font.serif};
    background-color: ${(props) => props.theme.color.grey300};

    display: ${(props) => (props.isOpen ? "block" : "none")};
    opacity: ${(props) => (props.isOpen ? 1 : 0)};
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;

    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.1);

    transition: opacity 0.2s ease-out;

    & > a {
        display: block;
        font-size: 16px;
        text-decoration: none;
        color: ${(props) => props.theme.color.white};
        padding: 20px ${(props) => props.theme.grid.gutter / 2}px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        &:last-child {
            border: none;
        }
        &:hover {
            color: ${(props) => props.theme.color.primary};
        }
    }

    ${({ theme }) => theme.media.large`
        position: static;
        display: block;
        background-color: transparent;
        text-align: right;
        flex-grow: 1;
        opacity: 1;
        box-shadow: none;

        & > a {
            display: inline;
            margin-left: 20px;
            padding: 0;
            border: none;
            color: ${(props) => props.theme.color.text};
        }
    `};
`;

const ToggleBtn = styled.div`
    position: absolute;
    top: 4px;
    right: 0px;

    ${({ theme }) => theme.media.large`
        display: none;
    `};
`;

const Header = () => {
    const [navOpen, setNavOpen] = useState(false);
    return (
        <HeaderContainer>
            <HeaderContent>
                <Link to="/">
                    <Img src={logo} alt="Incorpora Állásportál" />
                </Link>
                <ToggleBtn onClick={() => setNavOpen(!navOpen)}>
                    <Hamburger isActive={navOpen} />
                </ToggleBtn>
                <Nav isOpen={navOpen}>
                    <a href="https://www.incorpora-halozat.com/rolunk">Rólunk</a>
                    <a href="https://www.incorpora-halozat.com/allaskeresoknek">Álláskeresőknek</a>
                    <a href="https://www.incorpora-halozat.com/kontakt">Kontakt</a>
                </Nav>
            </HeaderContent>
        </HeaderContainer>
    );
};

export default Header;
