import { css } from "styled-components";
const sizes = {
    large: 1140,
    medium: 760,
    small: 321,
};
export default Object.keys(sizes).reduce((acc, label) => {
    acc[label] = (...args) => css`
        @media (min-width: ${sizes[label]}px) {
            ${css(...args)};
        }
    `;
    return acc;
}, {});
