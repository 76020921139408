import React, { useState, useEffect } from "react";
import { Router, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";

import Theme from "../../theme/Theme";
import GlobalStyles from "../../theme/GlobalStyles";
import { getSpecialities } from "../../api/common";
import styled from "styled-components";

import Header from "../common/Header";
import Footer from "../common/Footer";
import Home from "../Home";
import SearchResults from "../SearchResults";
import Job from "../Job";
import NotFound from "../NotFound";

const history = createBrowserHistory();

const PageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
`;

const App = () => {
    const [specialities, setSpecialities] = useState();

    useEffect(() => {
        getSpecialities().then((result) => setSpecialities(result));
    }, []);

    return (
        <Theme>
            <GlobalStyles />
            <Router history={history}>
                <PageWrapper>
                    <Header />
                    <Switch>
                        <Route exact path="/" component={(props) => <Home {...props} specialities={specialities} />} />
                        <Route
                            path="/kereses/:keyword?/:field?"
                            component={(props) => <SearchResults {...props} specialities={specialities} />}
                        />
                        <Route
                            path="/allas/:jobId"
                            component={(props) => <Job {...props} specialities={specialities} />}
                        />
                        <Route component={(props) => <NotFound {...props} specialities={specialities} />} />
                    </Switch>
                    <Footer />
                </PageWrapper>
            </Router>
        </Theme>
    );
};

export default App;
