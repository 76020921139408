import React from "react";
import styled from "styled-components";

import heroBackground from "../../images/incorpora-background.jpg";
import SearchForm from "../common/SearchForm";

const HeroWrapper = styled.div`
    background-image: url(${heroBackground});
    background-size: cover;
`;

const HeroOverlay = styled.div`
    background-color: rgba(0, 174, 239, 0.95);
    padding: 0 ${(props) => props.theme.grid.gutter / 2}px;

    ${({ theme }) => theme.media.large`
        padding: 0 0 0 ${(props) => props.theme.grid.gutter / 2}px;
        width: 50%;
        display: flex;
        justify-content: flex-end;
   `};
`;

const HeroContent = styled.div`
    padding: 40px 0;

    ${({ theme }) => theme.media.medium`
        padding: 80px 0 80px 0;
   `};

    ${({ theme }) => theme.media.large`
        flex-grow: 1;
        max-width: ${(props) => props.theme.media.maxWidth / 2}px;
        padding: 80px 70px 80px 0;
   `};

    & > h2 {
        margin: 0 0 20px 0;
        font-family: ${(props) => props.theme.font.serif};
        font-size: 32px;
        color: ${(props) => props.theme.color.white};
    }

    & > p {
        margin: 0 0 20px 0;
        font-family: ${(props) => props.theme.font.sans};
        font-size: 16px;
        line-height: 24px;
        color: ${(props) => props.theme.color.white};

        & > span {
            font-weight: 700;
        }
    }
`;

const Hero = ({ specialities, history }) => {
    return (
        <HeroWrapper id="kereso">
            <HeroOverlay>
                <HeroContent>
                    <h2>Elakadt a munkaerő-piacon?</h2>
                    <p>
                        Keressen állásajánlataink között, mentoraink <span>egyénre szabott módszerekkel</span>{" "}
                        támogatják az Ön számára legjobb munkahely megtalálásában és megtartásában
                    </p>
                    <SearchForm specialities={specialities} history={history} />
                </HeroContent>
            </HeroOverlay>
        </HeroWrapper>
    );
};

export default Hero;
