import React from "react";
import styled from "styled-components";

const CardContainer = styled.div`
    background-color: ${(props) => props.theme.color.white};
    padding: 20px;
    border-radius: 3px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
    height: 100%;
    display: flex;
    flex-direction: column;
    border-bottom: 3px solid ${({ theme, color }) => theme.color[color]};
`;

const Card = ({ children, color = "primary" }) => {
    return <CardContainer color={color}>{children}</CardContainer>;
};

export default Card;
