import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import HeroCompact from "../common/HeroCompact";
import { searchJobs } from "../../api/common";
import styled from "styled-components";

import Container from "../common/Container";
import Loader from "../common/Loader";
import JobList from "./JobList";

const Wrapper = styled.div`
    flex-grow: 1;
    background-color: ${(props) => props.theme.color.grey100};
`;

const SearchResults = ({
    specialities,
    match: {
        params: { keyword, field },
    },
}) => {
    const [searchResultList, setSearchResultList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);
        let data = {};
        if (keyword && keyword !== "&") data["wordshred"] = keyword;
        if (field) data["speciality"] = field;
        searchJobs(data).then((result) => {
            setSearchResultList(result.result);
            setIsLoading(false);
        });
    }, [keyword, field]);

    return (
        <Wrapper>
            <Helmet>
                <title>Keresés - Incorpora Állásportál</title>
            </Helmet>
            <HeroCompact
                title="Állásajánlatok"
                description="Az Ön keresési feltételei alapján"
                specialities={specialities}
            />
            {isLoading && <Loader text="Állásajánlatok keresése..." />}
            {!isLoading && searchResultList && (
                <Container background="grey100">
                    <JobList searchResultList={searchResultList} />
                </Container>
            )}
            {!isLoading && !searchResultList && (
                <Container background="grey100">
                    <p>Sajnos a keres során nem találtunk ilyen pozíciót, próbáljon egy új keresést.</p>
                </Container>
            )}
        </Wrapper>
    );
};

export default SearchResults;
