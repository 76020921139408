import React from "react";
import { Helmet } from "react-helmet";

const HelmetWrapper = ({ meta }) => {
    return (
        <Helmet>
            <title>{meta.title}</title>
            <meta name="description" content={meta.description} />
        </Helmet>
    );
};

export default HelmetWrapper;
