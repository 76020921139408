import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";

import { getJob } from "../../api/common";

import Container from "../common/Container";
import HeroCompact from "../common/HeroCompact";
import Loader from "../common/Loader";
import JobDetails from "./JobDetails";
import ApplicationForm from "./ApplicationForm";

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

const Wrapper = styled.div`
    flex-grow: 1;
`;

const Row = styled.div`
    ${({ theme }) => theme.media.large`
        display: flex;
   `};
`;

const Col = styled.div`
    ${({ theme }) => theme.media.large` 
    width: 50%;
    &:first-child {
        padding: 0 ${(props) => props.theme.grid.gutter / 2}px 0 0;
    }
    &:last-child {
        padding: 0 0 0 ${(props) => props.theme.grid.gutter / 2}px;
        `};
`;

const Job = ({
    specialities,
    match: {
        params: { jobId },
    },
}) => {
    const [job, setJob] = useState();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);
        getJob(jobId).then((result) => {
            setJob(result);
            setIsLoading(false);
        });
    }, [jobId]);

    return (
        <Wrapper>
            {job && (
                <Helmet>
                    <title>{capitalizeFirstLetter(job.posname)} | Incorpora Állásportál</title>
                    <meta name="description" content={job.posdescr} />
                </Helmet>
            )}
            <HeroCompact
                title="Állásajánlatok"
                description="Az Ön keresési feltételei alapján"
                specialities={specialities}
            />
            <Container>
                {isLoading && <Loader text="Csak egy pillanat..." />}
                {!isLoading && job && (
                    <Row>
                        <Col className="jobDetails">
                            <JobDetails job={job} />
                        </Col>
                        <Col>
                            <ApplicationForm jobId={jobId} />
                        </Col>
                    </Row>
                )}
            </Container>
        </Wrapper>
    );
};

export default Job;
