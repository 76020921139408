import React from "react";
import styled from "styled-components";

const LoaderWrapper = styled.div`
    padding: 50px 0;
`;

const LoaderContent = styled.div`
    font-family: ${(props) => props.theme.font.sans};
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    color: ${(props) => props.theme.color.grey200};
`;

const Loader = ({ text }) => {
    return (
        <LoaderWrapper>
            <LoaderContent>{text}</LoaderContent>
        </LoaderWrapper>
    );
};

export default Loader;
