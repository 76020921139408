import axios from "axios";
import qs from "qs";

const apiRoot = "https://incorpora.damilinformatika.hu/index.php/Incorporajobs3c";

const handleErrors = (error) => console.log(error);

const transformSpecialities = (data) => {
    return data.result.map((item) => ({
        value: item.fsCode,
        label: item.fsValue,
    }));
};

export const getSpecialities = async () => {
    return axios({
        url: `${apiRoot}/listSpecialities`,
        method: "get",
        mode: "no-cors",
    })
        .then((response) => transformSpecialities(response.data))
        .catch((error) => handleErrors(error));
};

export const getFeaturedJobs = async () => {
    return axios({
        url: `${apiRoot}/listPositions/kiemelt`,
        method: "get",
        mode: "no-cors",
    })
        .then((response) => (response.data.result ? response.data.result : []))
        .catch((error) => handleErrors(error));
};

export const searchJobs = async (data) => {
    return axios
        .post(`${apiRoot}/listPositions`, qs.stringify(data), {
            mode: "no-cors",
            headers: { "content-type": "application/x-www-form-urlencoded" },
        })
        .then((response) => response.data)
        .catch((error) => handleErrors(error));
};

export const getJob = async (jobId = 0) => {
    return axios({
        url: `${apiRoot}/onePosition/${jobId}`,
        method: "get",
        mode: "no-cors",
    })
        .then((response) => (response.data.result ? response.data.result : null))
        .catch((error) => handleErrors(error));
};

export const sendApplication = async (data) => {
    return axios
        .post(`${apiRoot}/sendApplication`, qs.stringify(data), {
            mode: "no-cors",
            headers: { "content-type": "application/x-www-form-urlencoded" },
        })
        .then((response) => response.data)
        .catch((error) => handleErrors(error));
};
