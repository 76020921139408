import React from "react";
import styled from "styled-components";

import Container from "../common/Container";

import laCaixaLogo from "../../images/laCaixa-logo.png";
import ertseLogo from "../../images/erste-logo.png";
import maltaiLogo from "../../images/maltai-logo.png";

const LogoList = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;

    & img {
        flex-grow: 0;
        flex-shrink: 0;
        height: 22px;
        width: auto;
        align-self: center;
        margin: 15px;

        ${({ theme }) => theme.media.medium`
            margin: 20px;
            height: 40px;
            &:first-child {
                margin-left: 0;
            }
            &:last-child {
                margin-right: 0;
            }
        `};

        ${({ theme }) => theme.media.large`
            margin: 30px;
            height: 50px;
            &:first-child {
                margin-left: 0;
            }
            &:last-child {
                margin-right: 0;
            }
        `};
    }
`;

const Heading = styled.h2`
    font-family: ${(props) => props.theme.font.serif};
    font-size: 32px;
    margin: 0;
    text-align: center;

    & span {
        color: ${(props) => props.theme.color.primary};
    }
`;

const Text = styled.p`
    text-align: center;
    max-width: 540px;
    margin: 20px auto 0;
    font-size: 16px;
    ${({ theme }) => theme.media.medium`
        margin: 40px auto 0;
    `};
`;

const AboutUs = () => {
    return (
        <Container>
            <Heading>
                Incorpora<span> - a felelős foglalkoztatásért</span>
            </Heading>
            <LogoList>
                <img src={laCaixaLogo} alt="" />
                <img src={ertseLogo} alt="" />
                <img src={maltaiLogo} alt="" />
            </LogoList>
            <Text>
                Segítünk a tehetségeknek megtalálni a legjobb vállalatokat és a cégeknek segítünk, hogy sokféle tehetség
                jusson el hozzájuk. Szolgáltatásaink térítésmentesek az ügyfelek és a vállalatok számára is. Hálózat
                vagyunk, melyben civil szervezetek, cégek és önkéntesek dolgozunk együtt, hogy tegyünk a felelős
                foglalkoztatásért.
            </Text>
            <Text>
                Csatlakozzon az Incorpora-hoz az <strong>ERSTE Alapítvány</strong> és a{" "}
                <strong>La Caixa Alapítvány</strong> kezdeményezéséhez, amit a
                <strong>Magyar Máltai Szeretetszolgálat</strong> koordinál!
            </Text>
        </Container>
    );
};

export default AboutUs;
