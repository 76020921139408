import React from "react";
import styled from "styled-components";

const Section = styled.section`
    background-color: ${(props) => props.theme.color[props.background]};
    padding: 0 ${(props) => props.theme.grid.gutter / 2}px;
`;

const Content = styled.div`
    max-width: ${(props) => props.theme.media.maxWidth}px;
    margin: 0 auto;
    padding: 40px 0;

    ${({ theme }) => theme.media.large`
        padding: 80px 0;
   `};
`;

const Container = ({ background = "white", children }) => {
    return (
        <Section background={background}>
            <Content>{children}</Content>
        </Section>
    );
};

export default Container;
