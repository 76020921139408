import React from "react";
import styled from "styled-components";

const FooterContainer = styled.section`
    background-color: ${(props) => props.theme.color.grey300};
    padding: 0 ${(props) => props.theme.grid.gutter / 2}px;
`;

const Content = styled.div`
    max-width: ${(props) => props.theme.media.maxWidth}px;
    margin: 0 auto;
    padding: 20px 0;
    color: ${(props) => props.theme.color.grey200};
    text-align: center;

    ${({ theme }) => theme.media.large`
        padding: 40px 0;
   `};
`;

const Footer = () => {
    return (
        <FooterContainer>
            <Content>©2020 Incorpora | +36 30 / 644 7734 | info@incorpora-halozat.com</Content>
        </FooterContainer>
    );
};

Footer.propTypes = {};

export default Footer;
