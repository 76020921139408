import React from "react";
import styled from "styled-components";

import Container from "../common/Container";
import StoryCard from "./StoryCard";

import zsolt from "../../images/story-zsolt.jpg";
import judit from "../../images/story-judit.jpg";
import virag from "../../images/story-virag.jpg";
import bela from "../../images/story-bela.jpg";

const stories = [
    {
        name: "Zsolt",
        title: "pályakezdő fiatal",
        description:
            "„Vidéki egyetemen végeztem, de a fővárosba felköltözve nem találtam a végzettségemnek megfelelő munkakört. Az Incorpora programnak köszönhetően viszont rengeteg munkáltatót ismertem meg, és az álláskeresők között is sok új kapcsolatom lett.”",
        image: zsolt,
    },
    {
        name: "Virág",
        title: "gyersről visszatérő, vállalkozóvá váló anyuka",
        description:
            "„A kislányom születése után nem tudtam visszamenni az előző munkakörömbe, ami teljes munkaidőt kíván. Váltanom kellett, és egy vállalkozás elindítása mellett döntöttem. Az Incorpora Vállalkozás Indítási programjának köszönhetően lépésről lépésre tudtam haladni, és beindítottam design ékszer cégemet.”",
        image: virag,
    },
    {
        name: "Béla",
        title: "életkora miatt diszkriminált",
        description:
            "„A cégemnél leépítések voltak, én is elveszítettem a munkám... 55 éves koromra hivatkozva a legtöbb helyen visszautasították a jelentkezésem. Az Incorpora Programnak köszönhetem, hogy megismertem mostani munkáltatóm, aki számára a tapasztalatom érték és nem hátrány.”",
        image: bela,
    },
    {
        name: "Judit",
        title: "megváltozott muknaképességű",
        description:
            "„Sajnos egy komoly betegség keresztülhúzta felfelé ívelő karrierem. Hosszú éveket kellett kihagynom, ezalatt kiestem a könyvelői gyakorlatból, s már a teljes munkaidőt sem tudtam vállalni. Az Incorpora tanácsadóján keresztül találtam egy olyan munkahelyet, ahol biztosították a részmunkaidőt, esetenként az otthoni munkavégzést is.”",
        image: judit,
    },
];

const Heading = styled.h2`
    font-family: ${(props) => props.theme.font.serif};
    font-size: 32px;
    margin: 0;
    text-align: center;
`;

const Divider = styled.hr`
    width: 100px;
    height: 1px;
    margin: 20px auto;
    border: none;
    background-color: rgba(0, 0, 0, 0.2);
    ${({ theme }) => theme.media.medium`
    margin: 40px auto;
    `};
`;

const CardWrapper = styled.div`
    ${({ theme }) => theme.media.large`
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: stretch;
    `};
`;

const Stories = () => {
    return (
        <Container background="grey100">
            <Heading>Sikersztorik</Heading>
            <Divider />
            <CardWrapper>
                {stories.map((story) => (
                    <StoryCard
                        name={story.name}
                        title={story.title}
                        description={story.description}
                        image={story.image}
                    />
                ))}
            </CardWrapper>
        </Container>
    );
};

export default Stories;
