import React from "react";
import styled from "styled-components";
import Card from "../common/Card";
import Button from "../common/Button";
import noteIcon from "../../images/note.png";

const JobCardWrapper = styled.div`
    margin-bottom: 15px;
    &:last-child: {
        margin: 0;
    }
    ${({ theme }) => theme.media.large`
        width: 30%;
        margin: 15px; 
    `};
`;
const CardHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;
const CardContent = styled.div`
    padding: 20px 0;
    flex-grow: 1;
`;
const CardAction = styled.div`
    text-align: center;
`;
const Icon = styled.img`
    height: 32px;
    margin-right: 20px;
`;
const Title = styled.span`
    font-weight: 700;
    &:first-letter {
        text-transform: uppercase;
    }
`;
const Description = styled.span``;

const JobCard = ({ job, handleClick, color }) => {
    return (
        <JobCardWrapper>
            <Card color={color}>
                <CardHeader>
                    <Icon src={noteIcon} alt="" />
                    <Title>{job.fsJobDescription}</Title>
                </CardHeader>
                <CardContent>
                    <Description>{job.esJobAddress}</Description>
                </CardContent>
                <CardAction>
                    <Button kind="outlined" color="secondary" onClick={() => handleClick(job.flEmplHuntingID)}>
                        Részletek
                    </Button>
                </CardAction>
            </Card>
        </JobCardWrapper>
    );
};

export default JobCard;
