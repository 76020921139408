import React from "react";
import { Helmet } from "react-helmet";
import Container from "../common/Container";
import styled from "styled-components";
import SearchForm from "../common/SearchForm";
import FeaturedJobs from "../Home/FeaturedJobs";

const Wrapper = styled.div`
    flex-grow: 1;
    background-color: ${(props) => props.theme.color.grey100};
`;

const Heading = styled.h2`
    font-family: ${(props) => props.theme.font.serif};
    font-size: 32px;
    margin: 0;
    text-align: center;

    & span {
        color: ${(props) => props.theme.color.primary};
    }
`;

const P = styled.p`
    text-align: center;
`;

const SearchWrapper = styled.div`
    max-width: 550px;
    margin: 0 auto;
`;

const NotFound = ({ specialities }) => {
    return (
        <Wrapper>
            <Helmet>
                <title>404 - Az oldal nem található | Incorpora Állásportál</title>
            </Helmet>
            <Container background="grey100">
                <Heading>Az Ön által keresett oldal nem található</Heading>
                <P>Próbáljon egy új keresést:</P>
                <SearchWrapper>
                    <SearchForm specialities={specialities} />
                </SearchWrapper>
            </Container>
            <FeaturedJobs />
        </Wrapper>
    );
};

export default NotFound;
