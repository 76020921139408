import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import JobCard from "./JobCard";

const CardWrapper = styled.div`
    ${({ theme }) => theme.media.large`
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: stretch;
    `};
`;

const JobList = ({ searchResultList, color }) => {
    const history = useHistory();
    const handleClick = (jobId) => {
        console.log(jobId);
        const url = `/allas/${jobId}`;
        history.push(url);
    };
    return (
        <CardWrapper>
            {searchResultList &&
                searchResultList.map((job) => <JobCard job={job} handleClick={handleClick} color={color} />)}
        </CardWrapper>
    );
};

export default JobList;
