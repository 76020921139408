import React from "react";
import "./Hamburger.css";

const Hamburger = ({ isActive }) => {
    return (
        <div className={`hamburger hamburger--spin ${isActive ? "is-active" : ""}`}>
            <div className="hamburger-box">
                <div className="hamburger-inner"></div>
            </div>
        </div>
    );
};

export default Hamburger;
