import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { getFeaturedJobs } from "../../api/common";

import JobList from "../SearchResults/JobList";
import Container from "../common/Container";
import Button from "../common/Button";
import { HashLink as Link } from "react-router-hash-link";

const Heading = styled.h2`
    font-family: ${(props) => props.theme.font.serif};
    font-size: 32px;
    margin: 0;
    text-align: center;
    color: ${(props) => props.theme.color.white};
`;

const Divider = styled.hr`
    width: 100px;
    height: 1px;
    margin: 20px auto;
    border: none;
    background-color: rgba(255, 255, 255, 0.3);
    ${({ theme }) => theme.media.medium`
        margin: 40px auto;
    `};
`;

const BtnWrapper = styled.div`
    text-align: center;
    margin-top: 20px;
`;

const FeaturedJobs = () => {
    const [jobs, setJobs] = useState();
    useEffect(() => {
        getFeaturedJobs().then((result) => setJobs(result));
    }, []);

    return (
        <Container background="primary">
            <Heading>Kiemelt állásajánlataink</Heading>
            <Divider />
            <JobList searchResultList={jobs} color="secondary" />
            <BtnWrapper>
                <Link smooth to="/#kereso">
                    <Button color="secondary">Keresés az ajánlatok között</Button>
                </Link>
            </BtnWrapper>
        </Container>
    );
};

export default FeaturedJobs;
